import React from 'react'

interface CookiePolicyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const CookiePolicy: React.FC<CookiePolicyProps> = ({ fields }) => {
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 3000)
  })

  return (
    <section>
      {loaded && (
        <div
          className="container py-lg-5 py-3"
          dangerouslySetInnerHTML={{ __html: fields.script || '' }}
        />
      )}
    </section>
  )
}

export default CookiePolicy
