import React from 'react'

// Third Party
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

interface SearchProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledNextGenHeader = styled.section`
  padding-top: 70px;
  padding-bottom: 35px;
  background-color: ${({ theme }) => theme.color.grey};

  .swiper-scrollbar {
    height: 30px;
    width: 430px;
    cursor: pointer;
    border-radius: 100px;
    padding: 3px;
  }

  .swiper-scrollbar-drag {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.color.secondary};
  }

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 50px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  @media screen and (max-width: 1199px) {
    & h2 {
      font-size: 40px;
      line-height: 40px;
      padding-bottom: 15px;
    }
  }

  @media screen and (max-width: 575px) {
    padding-top: 20px;
    padding-bottom: 20px;

    .swiper-scrollbar {
      width: 200px;
    }
  }
`

const StyledSVG = styled.svg`
  /* margin-left: auto; */

  @media screen and (max-width: 575px) {
    height: 100px;
  }
`

const TheNextGenHeader: React.FC<SearchProps> = ({ fields }) => {
  // const [swiper, setSwiper] = React.useState<any>(null)

  return (
    <>
      <StyledNextGenHeader>
        <div className="d-flex pb-lg-5 justify-content-center">
          <StyledSVG
            width="1456"
            height="150"
            viewBox="0 0 1456 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 21.5493H39.4825V148.732H63.6814V21.5493H102.952V1.90141H0V21.5493Z"
              fill="#DADADA"
            />
            <path
              d="M221.071 148.732H245.27V1.90141H221.071V64.8592H151.658V1.90141H127.459V148.732H151.658V84.5071H221.071V148.732Z"
              fill="#DADADA"
            />
            <path
              d="M358.639 1.69014H277.127V148.732H358.639V129.085H301.326V84.0845H352.271V64.4366H301.326V21.338H358.639V1.69014Z"
              fill="#DADADA"
            />
            <path
              d="M540.594 1.69014V111.127L467.784 1.69014H443.586V148.732H467.784V39.0845L540.594 148.732H564.793V1.69014H540.594Z"
              fill="#DADADA"
            />
            <path
              d="M678.082 1.69014H596.57V148.732H678.082V129.085H620.769V84.0845H671.714V64.4366H620.769V21.338H678.082V1.69014Z"
              fill="#DADADA"
            />
            <path
              d="M876.697 21.5493H916.179V148.732H940.378V21.5493H979.648V1.90141H876.697V21.5493Z"
              fill="#DADADA"
            />
            <path
              d="M1126.59 0C1084.98 0 1051.23 31.0563 1051.23 75C1051.23 119.155 1084.98 150 1126.59 150C1166.28 150 1195.15 121.056 1199.61 87.0423V68.2394H1118.31V87.4648H1173.71C1170.1 112.183 1153.33 128.239 1126.59 128.239C1097.29 128.239 1076.07 107.324 1076.07 75C1076.07 42.6761 1097.29 21.9718 1126.59 21.9718C1144.21 21.9718 1158.21 29.3662 1166.07 44.1549H1195.15C1183.9 15.4225 1157.37 0 1126.59 0Z"
              fill="#DADADA"
            />
            <path
              d="M1304.99 1.69014H1223.48V148.732H1304.99V129.085H1247.67V84.0845H1298.62V64.4366H1247.67V21.338H1304.99V1.69014Z"
              fill="#DADADA"
            />
            <path
              d="M1431.8 1.69014V111.127L1358.99 1.69014H1334.79V148.732H1358.99V39.0845L1431.8 148.732H1456V1.69014H1431.8Z"
              fill="#DADADA"
            />
            <path
              d="M817.016 70.082C840.003 56.7799 855.848 31.8188 856.963 3H837.109C835.467 34.6283 810.041 59.8367 779.016 59.8367C747.99 59.8367 722.559 34.6253 720.919 3H701.068C702.18 31.8188 718.025 56.7799 741.013 70.082C717.164 83.8818 701 110.227 701 140.412V146.891H720.834V140.412C720.834 107.279 746.932 80.3362 779.001 80.3362C811.071 80.3362 837.169 107.294 837.169 140.412V147H857V140.412C857.028 110.227 840.864 83.8818 817.016 70.082Z"
              fill="#DADADA"
              stroke="#DADADA"
              strokeWidth="3"
            />
          </StyledSVG>
        </div>
        <div className="container py-sm-4">
          <div className="row">
            <div className="col-lg-4">
              <ParseContent content={fields.title || ''} />
            </div>
            <div className="col-lg-8">
              <ParseContent content={fields.description || ''} />
            </div>
          </div>
          <div className="d-flex justify-content-center pt-5">
            <ButtonPrimary to={fields.supportlink?.url}>
              {fields.supportlink?.title}
            </ButtonPrimary>
          </div>
        </div>
      </StyledNextGenHeader>
    </>
  )
}

export default TheNextGenHeader
