import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface WorldmapProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledWorldmap = styled.div`
  padding: 100px 0;

  @media screen and (max-width: 1199px) {
    padding: 50px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 15px 0;
  }
`

const Content = styled(ParseContent)`
  & h2 {
    font-size: 50px;
    line-height: 50px;
    padding-bottom: 0;
    position: relative;
    font-weight: ${({ theme }) => theme.font.weight.bold};

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  & p {
    font-size: 14px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  @media screen and (max-width: 575px) {
    & h2 {
      font-size: 30px;
      line-height: 30px;
    }

    & p {
      line-height: 20px;
    }
  }
`

const Worldmap: React.FC<WorldmapProps> = ({ fields }) => (
  <StyledWorldmap>
    <div className="container">
      <Content content={fields.title || ''} />
      <div className="pt-lg-5 pt-3 d-flex justify-content-center align-items-center">
        <Plaatjie image={fields.image} alt="" />
      </div>
    </div>
  </StyledWorldmap>
)

export default Worldmap
