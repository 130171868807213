import React from 'react'

// Third Party
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Scrollbar } from 'swiper'
import ParseContent from 'components/shared/ParseContent'

import 'swiper/css'
import 'swiper/css/scrollbar'
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import { Link } from 'gatsby'

interface SearchProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledNextGen = styled.section`
  padding-top: 70px;
  padding-bottom: 35px;
  background-color: ${({ theme }) => theme.color.grey};

  .swiper-scrollbar {
    height: 30px;
    width: 430px;
    cursor: pointer;
    border-radius: 100px;
    padding: 3px;
  }

  .swiper-scrollbar-drag {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.color.secondary};
  }

  @media screen and (max-width: 1199px) {
    & h1 {
      font-size: 50px;
      line-height: 50px;
    }
  }

  @media screen and (max-width: 575px) {
    padding-top: 20px;
    padding-bottom: 20px;

    .swiper-scrollbar {
      width: 200px;
    }
  }
`

const StyledSVG = styled.svg`
  /* margin-left: auto; */

  @media screen and (max-width: 575px) {
    height: 100px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
`

const StyledLinkPlaatjie = styled(Plaatjie)`
  height: 100%;

  & img {
    object-position: left center;
  }
`

const SwiperContainer = styled.div`
  padding: 45px 30px 0px;

  @media screen and (max-width: 991px) {
    padding: 30px 15px 0px;
  }
`

const StyledSliderContainer = styled.div<{ index: number }>`
  padding-bottom: 90px;
  background-color: #ffffff;

  & .swiper {
    overflow: visible;
  }

  & .swiper-slide {
    height: auto;
    width: 50%;
    border-right: 3px solid #1f55ff;

    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  & .swiper-slide:first-of-type {
    border-left: 3px solid #1f55ff !important;
  }

  & .swiper-slide:nth-child(${({ index }) => index + 1}) {
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 30px;
  }
`

const StyledContent = styled(ParseContent)`
  & h3 {
    font-size: 30px;
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  white-space: nowrap;
`

const BlogPart = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  padding: 30px;
  height: 325px;
  display: flex;
  align-items: center;
  position: relative;

  & h3 {
    color: ${({ theme }) => theme.color.light};
    font-size: 30px;
  }

  @media screen and (max-width: 991px) {
    height: 200px;
  }

  @media screen and (max-width: 575px) {
    padding: 20px;

    & h3 {
      font-size: 20px;
    }
  }
`

const StyledButtonSecondary = styled(ButtonSecondary)`
  position: absolute;
  bottom: 20px;
  right: 20px;

  & > a {
    color: ${({ theme }) => theme.color.primary};
    font-weight: 700;
    transition: all 0.2s ease;
  }

  & > a:hover {
    color: ${({ theme }) => theme.color.light};
  }
`

const DatePart = styled.div<{ opaque: boolean }>`
  & h2 {
    font-size: 50px;
    font-weight: 900;
    font-family: ${({ theme }) => theme.font.family.secondary} !important;
  }

  ${({ opaque }) =>
    opaque
      ? `
      position: absolute;
      left: 0;
      right: 0;
      bottom: 200px;
      margin-left: auto;
      margin-right: auto;
    `
      : `
      & h2 {
        font-size: 30px;
        padding-top: 15px;
      }
    `}
`

const ImagePart = styled.div`
  & img {
    cursor: pointer;
  }
`

const InactiveBlogPart = styled.div`
  height: 325px;
`

const ImagePartBig = styled.div``

const CloseImage = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: 800;
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
`

const ScrollDate = styled.div`
  font-size: 20px;
  color: #000000;
  font-weight: 300;
`

const TextPart = styled.div<{ opaque: boolean }>`
  min-height: 144px;

  ${({ opaque }) =>
    opaque &&
    `
      & h3 span {
        color: #DADADA !important;
      }
    `}

  @media screen and (max-width: 575px) {
    min-height: 130px;

    & h3 {
      font-size: 24px;
    }
  }
`

const OpaquePart = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

SwiperCore.use([Navigation, Scrollbar])

const TheNextGen: React.FC<SearchProps> = ({ fields }) => {
  // const [swiper, setSwiper] = React.useState<any>(null)
  const [activeSlide, setActiveSlide] = React.useState(-1)
  const [activeImage, setActiveImage] = React.useState(-1)

  // return null

  return (
    <>
      <StyledNextGen>
        <div className="d-flex justify-content-center">
          <StyledSVG
            width="1456"
            height="150"
            viewBox="0 0 1456 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 21.5493H39.4825V148.732H63.6814V21.5493H102.952V1.90141H0V21.5493Z"
              fill="#DADADA"
            />
            <path
              d="M221.071 148.732H245.27V1.90141H221.071V64.8592H151.658V1.90141H127.459V148.732H151.658V84.5071H221.071V148.732Z"
              fill="#DADADA"
            />
            <path
              d="M358.639 1.69014H277.127V148.732H358.639V129.085H301.326V84.0845H352.271V64.4366H301.326V21.338H358.639V1.69014Z"
              fill="#DADADA"
            />
            <path
              d="M540.594 1.69014V111.127L467.784 1.69014H443.586V148.732H467.784V39.0845L540.594 148.732H564.793V1.69014H540.594Z"
              fill="#DADADA"
            />
            <path
              d="M678.082 1.69014H596.57V148.732H678.082V129.085H620.769V84.0845H671.714V64.4366H620.769V21.338H678.082V1.69014Z"
              fill="#DADADA"
            />
            <path
              d="M876.697 21.5493H916.179V148.732H940.378V21.5493H979.648V1.90141H876.697V21.5493Z"
              fill="#DADADA"
            />
            <path
              d="M1126.59 0C1084.98 0 1051.23 31.0563 1051.23 75C1051.23 119.155 1084.98 150 1126.59 150C1166.28 150 1195.15 121.056 1199.61 87.0423V68.2394H1118.31V87.4648H1173.71C1170.1 112.183 1153.33 128.239 1126.59 128.239C1097.29 128.239 1076.07 107.324 1076.07 75C1076.07 42.6761 1097.29 21.9718 1126.59 21.9718C1144.21 21.9718 1158.21 29.3662 1166.07 44.1549H1195.15C1183.9 15.4225 1157.37 0 1126.59 0Z"
              fill="#DADADA"
            />
            <path
              d="M1304.99 1.69014H1223.48V148.732H1304.99V129.085H1247.67V84.0845H1298.62V64.4366H1247.67V21.338H1304.99V1.69014Z"
              fill="#DADADA"
            />
            <path
              d="M1431.8 1.69014V111.127L1358.99 1.69014H1334.79V148.732H1358.99V39.0845L1431.8 148.732H1456V1.69014H1431.8Z"
              fill="#DADADA"
            />
            <path
              d="M817.016 70.082C840.003 56.7799 855.848 31.8188 856.963 3H837.109C835.467 34.6283 810.041 59.8367 779.016 59.8367C747.99 59.8367 722.559 34.6253 720.919 3H701.068C702.18 31.8188 718.025 56.7799 741.013 70.082C717.164 83.8818 701 110.227 701 140.412V146.891H720.834V140.412C720.834 107.279 746.932 80.3362 779.001 80.3362C811.071 80.3362 837.169 107.294 837.169 140.412V147H857V140.412C857.028 110.227 840.864 83.8818 817.016 70.082Z"
              fill="#DADADA"
              stroke="#DADADA"
              strokeWidth="3"
            />
          </StyledSVG>
        </div>
        <div className="container py-sm-4">
          <div className="row">
            <div className="col-lg-4">
              <ParseContent content={fields.title || ''} />
            </div>
            <div className="col-lg-8">
              <ParseContent content={fields.description || ''} />
              <div className="pt-3 d-flex align-items-center gap-2 gap-sm-3">
                <ScrollDate>July ‘23</ScrollDate>
                <div>
                  <div className="swiper-scrollbar" />
                </div>
                <ScrollDate>July ‘24</ScrollDate>
              </div>
            </div>
          </div>
        </div>
      </StyledNextGen>
      <StyledSliderContainer index={activeSlide}>
        <div className="container">
          <Swiper
            // modules={[[Navigation]]}
            // onSwiper={(swiper) => {
            //   setSwiper(swiper)
            // }}
            // onInit={(s: any) => {
            //   setSwiper(s)
            // }}
            scrollbar={{ el: '.swiper-scrollbar', draggable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              991: {
                slidesPerView: 'auto',
                spaceBetween: 0,
              },
            }}
          >
            <div>
              {fields.items?.map((item, index) => (
                <SwiperSlide key={index}>
                  <SwiperContainer className="d-flex flex-column gap-2 gap-sm-4">
                    <TextPart
                      opaque={!item?.activetime || false}
                      className="d-flex gap-2 gap-sm-4"
                    >
                      <StyledContent content={item?.description || ''} />
                      {item?.activetime && (
                        <div>
                          <StyledButtonPrimary to={item?.link?.url}>
                            Press release
                          </StyledButtonPrimary>
                        </div>
                      )}
                    </TextPart>
                    {activeSlide !== index ? (
                      <div className="position-relative d-flex flex-column gap-4">
                        {item?.activetime ? (
                          <>
                            {item?.subdescription && (
                              <BlogPart>
                                <ParseContent
                                  content={item?.subdescription || ''}
                                />
                                <StyledButtonSecondary to={item?.link?.url || '/'}>
                                  {item?.link?.title}
                                </StyledButtonSecondary>
                              </BlogPart>
                            )}
                            {item?.vimeoUrl && (
                              <video controls playsInline loop className="h-full w-full object-cover">
                                <source src={`${item?.vimeoUrl}#t=1`} type="video/mp4" />
                              </video>
                            )}
                          </>
                        ) : (
                          <InactiveBlogPart className="position-relative">
                            <StyledPlaatjie
                              alt="big-innovation-image"
                              image={item?.images![0]?.image}
                            />
                            {!item?.activetime && <OpaquePart />}
                          </InactiveBlogPart>
                        )}

                        {item?.activetime ? (
                          <ImagePart className="row">
                            {item?.images
                              ?.slice(0, 2)
                              .map((image, subindex) => (
                                image.link ? (
                                  <Link to={image.link.url || '/'}
                                    key={subindex}
                                    className="col-6"
                                  >
                                    <StyledLinkPlaatjie
                                      image={image?.image}
                                      alt="innovation-image"
                                      key={subindex}
                                    />
                                  </Link>
                                ) : (
                                  <div
                                    key={subindex}
                                    onClick={() => {
                                      setActiveSlide(index)
                                      setActiveImage(subindex)
                                    }}
                                    className="col-6"
                                  >
                                    <StyledPlaatjie
                                      image={image?.image}
                                      alt="innovation-image"
                                      key={subindex}
                                    />
                                  </div>
                                )
                              ))}
                          </ImagePart>
                        ) : (
                          <ImagePart className="row">
                            {item?.images
                              ?.slice(1, 3)
                              .map((image, subindex) => (
                                <div
                                  key={subindex}
                                  // onClick={() => {
                                  //   setActiveSlide(index)
                                  //   setActiveImage(subindex)
                                  // }}
                                  className="col-6 position-relative"
                                >
                                  <StyledPlaatjie
                                    image={image?.image}
                                    alt="innovation-image"
                                    key={subindex}
                                  />
                                  {!item?.activetime && <OpaquePart />}
                                </div>
                              ))}
                          </ImagePart>
                        )}
                        {!item?.activetime && (
                          <DatePart opaque={true}>
                            <ParseContent content={item?.title || ''} />
                          </DatePart>
                        )}
                      </div>
                    ) : (
                      <ImagePartBig className="row gap-2 gap-lg-0 position-relative">
                        <div className="col-12 col-md-8">
                          <StyledPlaatjie
                            image={item?.images![activeImage]?.image}
                            alt="innovation-image"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="d-flex justify-content-end">
                            <CloseImage
                              onClick={() => {
                                setActiveSlide(-1)
                                setActiveImage(-1)
                              }}
                            >
                              CLOSE IMAGE
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <line
                                  x1="2.12132"
                                  y1="2"
                                  x2="16"
                                  y2="15.8787"
                                  stroke="#1f55ff"
                                  strokeWidth="3"
                                  strokeLinecap="round"
                                />
                                <line
                                  x1="16"
                                  y1="2.12132"
                                  x2="2.12132"
                                  y2="16"
                                  stroke="#1f55ff"
                                  strokeWidth="3"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </CloseImage>
                          </div>
                          <div className="pt-3 pt-md-5 px-2 px-md-4">
                            <ParseContent
                              content={
                                item?.images![activeImage]?.description || ''
                              }
                            />
                          </div>
                        </div>
                      </ImagePartBig>
                    )}
                    {item?.activetime && (
                      <DatePart opaque={!item?.activetime}>
                        <ParseContent content={item?.title || ''} />
                      </DatePart>
                    )}
                  </SwiperContainer>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </StyledSliderContainer>
    </>
  )
}

export default TheNextGen
