import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonPrimaryOutlined from 'components/elements/Buttons/ButtonPrimaryOutlined'

// Third Party
import styled from 'styled-components'
import { useFlexSearch } from 'react-use-flexsearch'
import { motion } from 'framer-motion'
import parse from 'html-react-parser'

interface SearchProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledSearch = styled.section``

const Type = styled.p`
  position: relative;
  font-weight: bold;
  margin-bottom: 0;
  color: ${({ theme }) => theme.color.primary};

  &::before {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`

const Input = styled.input`
  z-index: 2;
  position: relative;
  padding: 20px 25px;
  height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.dark};

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }

  @media (min-width: 576px) {
    width: 300px;
  }
`

const SearchResult = styled(motion.div)`
  margin: 0.5rem 0 0.5rem 0;
  background-color: ${({ theme }) => theme.color.grey};

  & h3 {
    color: ${({ theme }) => theme.color.dark};
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  padding: 30px 30px 40px 30px;
  margin: 0.5rem 0 0.5rem 0;
  height: 100%;

  & h1 {
    font-size: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.primary};
    padding-bottom: 20px;
  }

  @media screen and (max-width: 991px) {
    & h1 {
      font-size: 20px;
    }
  }
`

const Search: React.FC<SearchProps> = ({ fields }) => {
  // @ts-ignore
  const { posts, pages, products } =
    useStaticQuery<GatsbyTypes.searchQuery>(graphql`
      query search {
        posts: localSearchPosts {
          index
          store
        }
        pages: localSearchPages {
          index
          store
        }
        products: localSearchProducts {
          index
          store
        }
      }
    `)

  const [query, setQuery] = React.useState<string | null>(null)

  const pageResults = useFlexSearch(query, pages?.index, pages?.store)

  const postResults = useFlexSearch(query, posts?.index, posts?.store)

  const productResults = useFlexSearch(query, products?.index, products?.store)

  function handleSearchChange(e: any) {
    setQuery(e.target.value)
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = urlParams.get('query')

    setQuery(params)
  }, [])

  return (
    <StyledSearch>
      <div className="container py-lg-5 py-3">
        <div className="row">
          <div className="col-lg-6 flex-grow-1 mb-3">
            <Block>
              <h1>Search for all our pages and posts</h1>
              <Input
                type="search"
                className=""
                value={query || ''}
                onChange={handleSearchChange}
                placeholder="Search..."
              />
            </Block>
          </div>

          {query !== null &&
            pageResults.map((r: any, index: number) => (
              <div
                className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-3"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <SearchResult
                  initial={{ y: 50, opacity: 0.5 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 0.8,
                    type: 'spring',
                  }}
                  className="p-4 w-100 h-100 d-flex flex-column"
                >
                  <div className="d-flex justify-content-start">
                    <Type>{r.type}</Type>
                  </div>
                  <h3>{parse(r.title)}</h3>
                  <div className="d-flex justify-content-end mt-auto">
                    <ButtonPrimaryOutlined
                      className="secondary filled"
                      to={r.uri}
                    >
                      To this page
                    </ButtonPrimaryOutlined>
                  </div>
                </SearchResult>
              </div>
            ))}
          {query !== null &&
            postResults.map((r: any, index: number) => (
              <div
                className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-3"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <SearchResult
                  initial={{ y: 50, opacity: 0.5 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 0.8,
                    type: 'spring',
                  }}
                  className="p-4 w-100 h-100 d-flex flex-column"
                >
                  <div>
                    <div className="d-flex justify-content-start">
                      <Type>{r.type}</Type>
                    </div>
                    <h3>{parse(r.title)}</h3>
                  </div>

                  <div className="d-flex justify-content-end mt-auto">
                    <ButtonPrimaryOutlined
                      className="secondary filled"
                      to={r.uri}
                    >
                      To this news article
                    </ButtonPrimaryOutlined>
                  </div>
                </SearchResult>
              </div>
            ))}

          {query !== null &&
            productResults.map((r: any, index: number) => (
              <div
                className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-3"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <SearchResult
                  initial={{ y: 50, opacity: 0.5 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 0.8,
                    type: 'spring',
                  }}
                  className="p-4 w-100 h-100 d-flex flex-column"
                >
                  <div className="d-flex justify-content-start">
                    <Type>{r.type}</Type>
                  </div>
                  <h3>{parse(r.title)}</h3>
                  <div className="d-flex justify-content-end mt-auto">
                    <ButtonPrimaryOutlined
                      className="secondary filled"
                      to={r.uri}
                    >
                      To this product
                    </ButtonPrimaryOutlined>
                  </div>
                </SearchResult>
              </div>
            ))}
        </div>
        {(query?.length || 1) >= 3 &&
          postResults < 1 &&
          pageResults < 1 &&
          productResults < 1 && (
            <div className="d-flex text-center pt-lg-5 pt-3 flex-column font-family-primary flex-sm-row justify-content-center align-items-center">
              <h2 className="mb-0">
                Could not find anything. Try something else or &nbsp;
              </h2>
              <ButtonPrimary
                to="/"
                className="font-family-primary mt-4 mt-sm-0"
              >
                return to homepage
              </ButtonPrimary>
            </div>
          )}
      </div>
    </StyledSearch>
  )
}

export default Search
