import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface RealLifeProductsProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledRealLifeProducts = styled.section`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.primary};
    font-size: 30px;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  @media screen and (max-width: 991px) {
    & h2 {
      font-size: 24px;
      line-height: 28px;
    }
  }
`

const Block = styled.div`
  padding: 40px 30px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.grey};

  & h4 {
    color: ${({ theme }) => theme.color.dark};
    font-family: ${({ theme }) => theme.font.family.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & p {
    line-height: 24px;
    font-size: 16px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px;
  }

  @media screen and (max-width: 576px) {
    padding: 15px;
  }
`

const RealLifeProducts: React.FC<RealLifeProductsProps> = ({ fields }) => (
  <StyledRealLifeProducts>
    <div className="container py-lg-5 py-3">
      <ParseContent content={fields.title || ''} />
      <div className="row pt-lg-5 pt-3">
        {fields.products?.map((product, index) => (
          <div key={index} className="col-lg-6 my-lg-0 my-2">
            <Block className="d-flex align-items-center">
              <Plaatjie image={product?.image} alt="" />
              <div className="ps-5">
                <h4>{product?.title}</h4>
                <ParseContent content={product?.description || ''} />
              </div>
            </Block>
          </div>
        ))}
      </div>
    </div>
  </StyledRealLifeProducts>
)

export default RealLifeProducts
