import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Images
import Chevron from 'img/chevron.inline.svg'

// Components
import ButtonPrimaryOutlined from 'components/elements/Buttons/ButtonPrimaryOutlined'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { motion, AnimatePresence } from 'framer-motion'

interface ProductDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledProductDetail = styled.section``

const Title = styled.div`
  & h1 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.primary};
    font-size: 50px;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }
`

const SubTitle = styled.div`
  font-size: 30px;
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-family: ${({ theme }) => theme.font.family.primary};

  @media screen and (max-width: 991px) {
    font-size: 20px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  max-width: 320px;
  margin: 0 40px;
`

const ShortInfo = styled.div``

const OrangeBlock = styled.div`
  background: ${({ theme }) => theme.gradient.primaryLinearSwitched};
  width: 150%;
  padding: 40px 50px;

  @media screen and (max-width: 576px) {
    padding: 20px 25px;
    width: 100%;
  }
`

const OrangeBlockText = styled.div`
  width: 50%;

  & p {
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-family: ${({ theme }) => theme.font.family.primary};
    color: ${({ theme }) => theme.color.light};
    font-size: 16px;
    line-height: 30px;
  }

  & li {
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-family: ${({ theme }) => theme.font.family.primary};
  }

  @media screen and (max-width: 576px) {
    width: 100%;

    & p {
      font-size: 14px;
    }
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  padding: 40px 50px;
  font-family: ${({ theme }) => theme.font.family.primary};

  & h3 {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 20px;
  }

  & li {
    font-size: 14px;
    line-height: 30px;
  }

  & img {
    width: 100%;
    object-fit: contain !important;
    border-radius: 15px;
  }

  @media screen and (max-width: 991px) {
    padding: 30px 40px;
  }

  @media screen and (max-width: 576px) {
    padding: 15px;

    & li {
      font-size: 14px !important;
      line-height: 25px !important;
    }
  }
`

const TabBlock = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  padding: 0 50px;
  margin: 20px 0;
  font-family: ${({ theme }) => theme.font.family.primary};
  overflow: hidden;
  cursor: pointer;

  & h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin: 30px 0;
    color: ${({ theme }) => theme.color.dark};
  }

  & h3 {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 20px;
  }

  & p {
    font-size: 16px;
  }

  & li {
    font-size: 14px;
    line-height: 30px;
  }

  @media screen and (max-width: 991px) {
    padding: 0 40px;
    margin: 15px 0;
  }

  @media screen and (max-width: 576px) {
    padding: 0 15px;

    & h2 {
      margin: 20px 0;
    }
  }
`

const TabContent = styled.div`
  padding-bottom: 30px;
  cursor: normal;

  @media screen and (max-width: 991px) {
    padding-bottom: 20px;
  }
`

const SvgWrapper = styled.div<{ selected: boolean }>`
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 100%;
  padding: 5px;

  & svg {
    transition: all 0.3s ease;
  }

  ${({ selected }) =>
    selected &&
    css`
      & svg {
        transform: rotate(180deg);
      }
    `}
`

const ProductDetail: React.FC<ProductDetailProps> = ({ fields }) => {
  return (
    <StyledProductDetail>
      <div className="container py-sm-5 py-4">
        {/* TITLE */}
        <div className="d-sm-flex align-items-end">
          <Title>
            <ParseContent content={fields.title || ''} />
          </Title>
          <SubTitle className="ps-sm-4 pt-sm-0 pt-3">
            {fields.subtitle}
          </SubTitle>
        </div>

        {/* IMAGE WITH SHORTINFO */}
        <div className="py-sm-5 py-4 d-sm-flex align-items-center">
          <StyledPlaatjie image={fields.image} alt="" />
          <ShortInfo className="ms-sm-5">
            <OrangeBlock className="mt-sm-0 mt-4">
              <OrangeBlockText>
                <ParseContent content={fields.description || ''} />
              </OrangeBlockText>
            </OrangeBlock>
            {/* <ButtonPrimaryOutlined
              className="mt-sm-4 mt-3"
              to={fields.supportlink?.url || '/'}
            >
              {fields.supportlink?.title}
            </ButtonPrimaryOutlined> */}
          </ShortInfo>
        </div>

        {/* DETAILED INFORMATION */}
        <div>
          <Block>
            <div className="row">
              <div className="col-lg-6 pb-lg-0 pb-3">
                <ParseContent content={fields.detaildescription || ''} />
              </div>
              <div className="col-lg-6">
                <ParseContent content={fields.seconddetaildescription || ''} />
              </div>
              <div className="col-lg-12">
                <ParseContent
                  content={fields.wholewidthdetaildescription || ''}
                />
              </div>
            </div>
          </Block>
        </div>

        {/* TABS */}
        <div>
          {fields.tabs?.map((t, index) => {
            const [tab, setTab] = React.useState(false)

            return (
              <TabBlock onClick={() => (tab ? setTab(false) : setTab(true))}>
                <div className="d-flex align-items-center justify-content-between">
                  <h2>{t?.title}</h2>
                  <SvgWrapper selected={tab}>
                    <Chevron />
                  </SvgWrapper>
                </div>
                <AnimatePresence>
                  {tab && (
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: 'min-content' }}
                      exit={{ height: 0 }}
                      onClick={(e: any) => e.stopPropagation()}
                    >
                      <TabContent>
                        <ParseContent content={t?.description || ''} />
                      </TabContent>
                    </motion.div>
                  )}
                </AnimatePresence>
              </TabBlock>
            )
          })}
        </div>
      </div>
    </StyledProductDetail>
  )
}

export default ProductDetail
